    <section class="showcase">
        <img src="../../assets/Screenshot 2021-07-10 at 8.49.31 PM.png">
        <div class="overlay-banner"></div>
        <div class="content">
            <p class="welcome">We<span class="underline">lcome </span>To</p>
            <p class="name">Adel  Legal</p>
            <p >The firm has been committed to putting forth the best for the client, </p>
                <p style="margin-bottom: 2em;">which has enabled us to develop strong relationships over the last few years.</p>
                <a href="#" class="mbtn" [routerLink] ="['/contactus']">Contact Us<i class="fa fa-arrow-right" aria-hidden="true"></i></a>
        </div>
    </section>

