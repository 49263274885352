import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-practice-area',
  templateUrl: './practice-area.component.html',
  styleUrls: ['./practice-area.component.scss']
})
export class PracticeAreaComponent implements OnInit {
  display=0;
  constructor() { }

  ngOnInit(): void {
  }
  onClick(val){
    this.display=val;
  }
}
