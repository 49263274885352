<div class="banner">
    <div class="overlay"></div>
    <div class="content">
        <h2>We're Here.</h2>
<h3>Let's Stay In Touch</h3>
  </div>
</div>


<div class = "main-area-grid">
    <div class = "basic-detail">
        <div class = "basic-display">
            <div>
                <i class="fa fa-map-marker icon-css" aria-hidden="true"></i>
            </div>
            
            <div class = "display-helper">
                <p>
                    <span class="basic"><span class = "color-css">Delhi </span>Office</span>
                    E-79, East of Kailash, New Delhi-110065
                </p>
            </div>
        </div>

        <div class = "basic-display">
            <div>
                <i class="fa fa-map-marker icon-css" aria-hidden="true"></i>
            </div>
            
            <div class = "display-helper">
                <p>
                    <span class = "basic"><span class = "color-css">Nodia </span>Office</span>
                    605, Lincoln B, Grand Omaxe, Sector-93B, Noida-201307
                </p>
            </div>
        </div>

        <div class = "basic-display">
            <div>
                <i class="fa fa-phone icon-css" aria-hidden="true"></i>
            </div>
            
            <div class = "display-helper">
                <p>
                    <span class = "basic"><span class = "color-css">Call </span>Us On</span>
                    +91-11-42770072
                </p>
            </div>
        </div>

        <div class = "basic-display">
            <div>
                <i class="fa fa-envelope icon-css" aria-hidden="true"></i>
            </div>
            
            <div class = "display-helper">
                <p>
                   <span class="basic"><span class = "color-css">Email </span>Us On</span>
                   info@adellegal.in
                   <span class = "basic-light">(This email id is only for seeking information from us and is not an email id for service of summons/Notices/Documents in court proceedings).</span>
                </p>
            </div>
        </div>
    </div>
    <div>
        <h1 class="heading"><span class="underline">Plea</span>se Fill The Form</h1>
        <!-- <label>Your Name(required)</label> -->
        <!-- <br> -->
        <form [formGroup] = "contactUs" (ngSubmit) = "onSubmit($event)">
            <div class = "form-spacing">
                <span *ngIf = "!contactUs.get('name').valid && (contactUs.get('name').touched || submitted)">Name is not specified</span>
                <input type = "text" class = "input-css" placeholder="Name" formControlName = "name" name = "name">
            </div>

            <div class = "form-spacing">
                <span *ngIf = "(contactUs.get('contact').touched || submitted) && contactUs.get('contact').errors?.required">Contact is not specified</span>
                <span *ngIf = "(contactUs.get('contact').touched || submitted) && contactUs.get('contact').errors?.pattern">
                    Please Enter 10 digit Mobile Number.
                </span>
                <input type = "text" class = "input-css" placeholder="Contact No." formControlName = "contact" name = "contact">
            </div>
            
            <!-- <label>Your Email(required)</label> -->
            <div class = "form-spacing">
                <span *ngIf = "(contactUs.get('email').touched || submitted) && contactUs.get('email').errors?.required">Email is not specified</span>
                <span *ngIf = "(contactUs.get('email').touched || submitted) && contactUs.get('email').errors?.email">Email is invalid</span>
                <input type = "text" class = "input-css" placeholder="Email" formControlName = "email" name = "email">
            </div>
            
            <!-- <label>Subject</label> -->
            <div class = "form-spacing">
                <span *ngIf = "!contactUs.get('subject').valid && (contactUs.get('subject').touched || submitted)">Subject is not specified</span>
                <input type = "text" class = "input-css" placeholder="Subject" formControlName = "subject" name = "subject">
            </div>
            
            <!-- <label>Message</label> -->
            <div class = "form-spacing">
                <span *ngIf = "!contactUs.get('message').valid && (contactUs.get('message').touched || submitted)">Message is not specified</span>
                <textarea class = "textarea-css" placeholder="Message" formControlName = "message" name = "message"></textarea>
            </div>
            
            <button class = "mbtn" type = "submit">Contact</button>
        </form>
        
    </div>
    
</div>

<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.5675090600944!2d77.2443933146953!3d28.552716594440536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3c95ea4dc33%3A0x6468a2a02f7193c!2s79%2C%20Mount%20Kailash%20Apartment%2C%20East%20of%20Kailash%2C%20New%20Delhi%2C%20Delhi%20110065!5e0!3m2!1sen!2sin!4v1622533741035!5m2!1sen!2sin" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy"></iframe>

<!-- <app-footer></app-footer> -->