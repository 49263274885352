<h1 mat-dialog-title>Disclaimer:</h1>
<div mat-dialog-content>As per the rules of the Bar Council of India, law firms are not permitted to solicit work and advertise. By clicking the “I AGREE” button below and accessing this website (www.adellegal.in), you fully accept that you are seeking information of your own accord and volition and that no form of solicitation has taken place by Chambers of Nishank Tyagi or its members. You further accept that:

    <ul>
        <li>You wish to gain more information about us for your own reference and use.
        </li>
        <li>The information provided in this website has been provided to you on your specific request and any such information obtained or material downloaded from this website is completely at your own volition and any transmission, receipt or use of this site does not create any lawyer-client relationship.
        </li>
        <li>There has been no advertisement, solicitation, personal communication, invitation or inducement of any sort whatsoever from us or any of our members to solicit any work through this website.
        </li>
        <li>We are not liable for any consequence of any action taken by you relying on the material / information provided on this website.
        </li>
        <li>In cases where the user has any legal issues, he/she in all cases must seek independent legal advice.
        </li>
        <li>The contents of this website are the intellectual property of Adel Legal.
        </li>
    </ul>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" mat-dialog-close>I Agree</button>
  <button mat-button mat-dialog-close (click)="backClicked()">Cancel</button>

</div>