<div class="banner">
    <div class="overlay"></div>
    <div class="content">
        <h2>Practice Area</h2>
        <h3>WHAT WE ARE EXPERT AT</h3>
    </div>
</div>
<div class="container pa">
    <h2 class="heading"><span class="underline">Prac</span>tice Area</h2>
    <div class="row ">
        <div class="col-md-12 topic">
            <div [ngClass]="{'active': display==0}" (click)="onClick(0)">Dispute Management & Litigation
            </div>
            <div [ngClass]="{'active': display==1}" (click)="onClick(1)">Arbitration, Mediation & ADR
            </div>
            <div [ngClass]="{'active': display==2}" (click)="onClick(2)">Corporate & Commercial
            </div>
            <div [ngClass]="{'active': display==3}" (click)="onClick(3)">Real Estate
            </div>
            <div [ngClass]="{'active': display==4}" (click)="onClick(4)">Labour & Employment
            </div>
            <div [ngClass]="{'active': display==5}" (click)="onClick(5)">Family Law
            </div>
            <div [ngClass]="{'active': display==6}" (click)="onClick(6)">Pro Bono Services
        </div>
    </div>
    </div>
    <div class="row">
        <div class="content col-md-12" *ngIf="display==1">
            <h4 class="title">Arbitration, Mediation & ADR</h4>
            Our firm offers services to handle arbitration proceedings in entirety, right from the stage of appointment
            of arbitrators by the Court, obtaining/opposing any interim relief's pending, appointment, proceedings
            before the arbitrator(s) and enforcement/challenge to the arbitration award in court. Members of the Firm
            are providing full scale services and are catering to complete legal requirements of various clients.
            The ADR services provided by the Firm includes Arbitration, Mediation, Negotiation and Case Management. The
            Firm has represented clients in various complex Arbitration matters involving government bodies, private
            companies, international companies etc.
        </div>
        <div class="content col-md-12" *ngIf="display==2">
            <h4 class="title">Corporate & Commercial</h4>
            Adel Legal provides consultation on corporate and commercial law matters, including formation
            and association of Indian, foreign and mixed companies in India, joint ventures, foreign investments,
            initial public offerings, mergers, acquisitions, amalgamations, Public Private Partnership and power
            projects. We have the requisite expertise to assist Indian and Foreign companies on all aspects of legal,
            regulatory compliances.
            We have been instrumental in obtaining various orders in favour of its clients before the National Company
            Law Tribunal and the National Company Law Appellate Tribunal.
            We have an in-depth understanding of Insolvency and Bankruptcy Code and regularly advise leading companies
            and individuals, both domestic and international, on these issues.
            We handle all aspects of Litigation under the Insolvency and Bankruptcy Code and before the Debt Recovery
            Tribunals.
        </div>
        <div class="content col-md-12" *ngIf="display==0">
            <h4 class="title">Dispute Management & Litigation</h4>
            Our endeavour is to devise a litigation strategy with the commercial interests of our clients. We identify
            the best legal solution by looking beyond the traditional approaches and thinking creatively.
            Our team is highly experienced in providing a complete hand-holding in terms of preparing a pre-litigation
            strategy, dispute prevention strategy, Strategic projections, Recovery Settlement Strategy & post-litigation
            strategy.
            Some of the various litigation matters handled by the firm include:
            <div class="displayList">
            <ul class="long">
                <li><i class="fa fa-check" aria-hidden="true"></i> Constitutional matters</li>
                <li><i class="fa fa-check" aria-hidden="true"></i> Civil disputes</li>
                <li><i class="fa fa-check" aria-hidden="true"></i> Corporate disputes</li>
                <li><i class="fa fa-check" aria-hidden="true"></i> Criminal matters</li>
                <li><i class="fa fa-check" aria-hidden="true"></i> Constitutional matters</li>
                <li><i class="fa fa-check" aria-hidden="true"></i> Employment & labour disputes</li>
            </ul>
            <ul class="long">
                <li><i class="fa fa-check" aria-hidden="true"></i> Real estate Litigation</li>
                <li><i class="fa fa-check" aria-hidden="true"></i> Telecom disputes</li>
                <li><i class="fa fa-check" aria-hidden="true"></i> Insolvency & Bankruptcy disputes</li>
                <li><i class="fa fa-check" aria-hidden="true"></i> Cross-border litigation</li>
                <li><i class="fa fa-check" aria-hidden="true"></i> Consumer cases</li>
            </ul>
        </div>
        </div>
        <div class="content col-md-12" *ngIf="display==3">
            <h4 class="title">Real Estate</h4>
            Our Real Estate practice involves carrying out Due diligence of property documents, change in ownership
            documents and conducting title searches for others, homes, real estate projects, townships, industries and
            acquisitions. Our diversified clientele includes owners, developers, secured lenders, loan participants,
            government bodies, bondholders, borrowers, landlords and tenants.
        </div>

        <div class="content col-md-12" *ngIf="display==4">
            <h4 class="title">Labour & Employment</h4>
            We have a strong portfolio of clients and transactions in relation to employment, HR and labour laws and
            have been advising several of its clients, including multinational clients on labour and employment law
            related matters ranging from issues relating to appointment of employees, drafting of employment contracts
            for key personnel, drafting of termination letters/full and final settlement documents, gender/equal pay
            matters, preparation of compliance manuals, review of HR policies, issues relating to contract labour, law
            off, strikes, lockouts, retrenchment and settlement processes, internal disciplinary proceedings, action for
            employee misconduct and pre-dispute advisory employees.
            We have deep knowledge of labour related regulatory issues and we specialize in regulatory and disputes work
            in the employment sector. We have also advised on immigration laws and on work visa related issues.
        </div>

        <div class="content col-md-12" *ngIf="display==5">
            <h4 class="title">Family Law </h4>
            We deal with all Family Law related cases. We advise and provide legal services in matters relating to
            Marriage and Registration, matters relating to Guardians and Wards Act, Hindu Adoption and Maintenance Act,
            Special Marriage Act of 1954, Succession Act of 1925, Hindu Marriage Act of 1955, Dowry Prohibition Act of
            1961 and The Indian Divorce Act of 1869.
            Our proficiency extends to providing expert, time bound and effective assistance in various family disputes
            involving child custody battles, resolving usurping of property by the creation of fake power of attorney
            and other documentation. We prosecute and defend our client’s before various courts and tribunals and also
            provide pre-advisory assistance and consultation.

        </div>

        <div class="content col-md-12" *ngIf="display==6">
            <h4 class="title">Pro Bono Services
            </h4>
            We provide pro bono legal services to charitable/not for profit organizations who cannot otherwise afford
            legal counsel. We approach pro bono efforts with the same dedication and energy as work for commercial
            clients. We are always enthusiastic to work on projects that make a difference.
        </div>
    </div>
</div>