
<app-navbar></app-navbar>

<router-outlet>
</router-outlet> 
<app-footer></app-footer>
<div class="loading-overlay" *ngIf="loading">
    <mat-spinner class="loader"></mat-spinner>
</div>

