<app-banner></app-banner>
<div class="intro">
    <div class="box">

        <div class="content">
            <div class="part1">
                <h2 class="heading"><span class="underline">Adel</span> Legal</h2>
                <p>Adel Legal is a full service law firm offering legal services over a wide spectrum of
                    industry businesses. Adel Legal provides legal services to leading domestic and
                    international industrial and commercial companies, private equity companies, banks, insurances and
                    other
                    financial service providers. We at Adel Legal believe in providing practical,
                    innovative
                    and effective legal solutions to Clients.
                </p>
                <a [routerLink] ="['/about']" class="mbtn">Read More</a>
            </div>
            <div class="part2">
                <img src="../../assets/images/meeting.jpg">
            </div>
        </div>
    </div>
</div>
<h4 class="subheading">WHAT WE ARE EXPERT AT</h4>
<h2 class="heading">Practice Area</h2>
<div class="practice">
    <div class="card" *ngFor="let item of items">
        <div class="image">
            <img [src]="item.img" class="card-img-top" alt="...">
        </div>
        <div class="card-body">
            <h5 class="card-title">{{item.title}}</h5>
            <p class="card-text">{{item.text}}</p>
            <a href="#" class="mbtn">Learn More<i class="fa fa-arrow-right" aria-hidden="true"></i></a>
        </div>
    </div>

</div>

<div class="contact">
    <div class="overlay"></div>
    <div class="content">
        <h2>Need Legal Advice?
        </h2>
        <h3>We are here to help</h3>

        <a class="mbtn" [routerLink] ="['/contactus']">Contact</a>
    </div>
</div>

<div class="address">
    <div class="office">
        <div class="tab location">
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            <p class="text"><span class="title"><span class="highlight">Delhi </span>Office</span>E-79, East of Kailash,
                New Delhi-110065</p>
        </div>

        <div class=" tab location">
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            <p class="text"><span class="title"><span class="highlight">Noida </span>Office</span>605, Lincoln B, Grand
                Omaxe, Sector-93B, Noida-201307</p>
        </div>
        <div class="tab phone">
            <i class="fa fa-phone" aria-hidden="true"></i>
            <p class="text"><span class="title"><span class="highlight">Call</span> us on</span> +91-11-42770072</p>
        </div>
        <div class="tab email">
            <i class="fa fa-envelope" aria-hidden="true"></i>
            <div class="text">
                <p><span class="title"><span class="highlight">Email</span> us on</span>info@adellegal.in<span class="info">(This email id is only for seeking information from us and is not an
                        email id for service of summons/Notices/Documents in court proceedings).</span></p>

            </div>
        </div>


    </div>
   <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.5675090600944!2d77.2443933146953!3d28.552716594440536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3c95ea4dc33%3A0x6468a2a02f7193c!2s79%2C%20Mount%20Kailash%20Apartment%2C%20East%20of%20Kailash%2C%20New%20Delhi%2C%20Delhi%20110065!5e0!3m2!1sen!2sin!4v1622533741035!5m2!1sen!2sin" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
</div>