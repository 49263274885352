import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient} from '@angular/common/http';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  contactUs : FormGroup;
  messageCreated : any;
  http:HttpClient;
  submitted : boolean = false;
  constructor(http : HttpClient) {
    this.contactUs = new FormGroup({
      name : new FormControl(null,[Validators.required]),
      contact : new FormControl(null,[Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      email : new FormControl(null,[Validators.required,Validators.email]),
      subject : new FormControl(null,[Validators.required]),
      message: new FormControl(null,[Validators.required])
    })
    this.http = http;
  }

  ngOnInit(): void {
  }

  // onSubmit(){
  //   console.log('hello');
  //   console.log(this.contactUs.value);
  //   this.messageCreated = this.contactUs.value;
  //   let formData = new FormData();
  //   formData.append('name',this.contactUs.get('name').value);
  //   formData.append('email',this.contactUs.get('email').value);
  //   formData.append('_subject',this.contactUs.get('subject').value);
  //   formData.append('message',this.contactUs.get('message').value);
  //   console.log(formData);
  //   this.http.post('https://formsubmit.co/82beb7c72781f5ca9169baec59462c2c',formData)
  //   .subscribe(response => {
  //     console.log(response);
  //   });
  // }

  onSubmit(e: Event){
    this.submitted = true;
    this.messageCreated = this.contactUs.value;
    console.log(this.contactUs.value);
    if(this.contactUs.valid){
      emailjs.sendForm('service_m4m2qkq', 'template_km9veb9', e.target as HTMLFormElement, 'user_647qcq6Zhz0RmdBcZntki')
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    }else{
      console.log("Please fill the form correctly");
    }
    

  }

}
