<div class="banner">
    <div class="overlay"></div>
    <div class="content">
        <h2>About Us</h2>
        <h3>Get to know us better</h3>
    </div>
</div>

<div class="aboutUs">
    
        <h2 class="heading"><span class="underline">Abou</span>t Us</h2>
        <p>Adel Legal is a full service law firm offering legal services over a wide spectrum of industry businesses. Adel Legal provides legal services to leading domestic and international industrial and commercial companies, private equity companies, banks, insurances and other financial service providers. We at Adel Legal believe in providing practical, innovative and effective legal solutions to Clients. We always operate in accordance with our business principles of quality, unity, and integrity whilst providing a high level of legal and technical excellence. Adel Legal was established by founder Mr.Nishank Tyagi who has extensive experience in providing effective legal solutions to a wide array of Clients.
        </p>
        <p>We are always on the beat to safeguard the interests of our clients. With our cultured legal acumen, experience and reputable expertise in Litigation; Domestic and International Commercial Arbitration; Corporate, Commercial and Policy Advisory, we provide customized legal solutions with complete personal attention and end-to-end services to meet our clients’ needs.
        </p>
    <p>With offices in Delhi and Noida, we offer a full suite of services across a broad range of areas including Banking & Finance, Dispute Resolution & ADR, Corporate & Commercial, Restructuring & Insolvency, Real Estate, Family Law, Investigations and Labour & Employment.
    </p>
    <p>We guide our clients through difficult issues, bringing out the best understanding and judgment to each situation. By thinking on behalf of our clients every day, we anticipate what they want, provide what they need and build lasting relationships. 
    </p>
    <p>Our aim is to deliver comprehensive legal solutions to all legal requirements of our clients. We have a highly qualified and responsive team of lawyers comprising of young as well as senior legal professionals who have attained specific expertise in their specific area of laws. Our emphasis is on identifying the client’s needs down to the last detail, ensuring that our work is technically faultless and ultimately managing our cases to meet our client’s needs and requirements.
    </p>
    <p>We believe in giving back to the Society and providing free legal assistance to the underprivileged members of the society. Therefore, we take 25-30% of our cases pro-bono. We do not charge any professional fee from War victim, widow of Army Personnel, victim of acid attack, physically challenged and other impoverished and helpless clients.
    </p>
    
</div>

<div class="founder">
    <div class="box">
        <h2 class="heading"><span class="underline">Abou</span>t Founder</h2>
        <div class="row">
            <div class="col-md-4">
                
                        <img src="../../assets/images/founder.jpg">
                    <h2 class="name">Nishank Tyagi <span class="email">Email-nishank@adellegal.in</span></h2>
            </div>
                <div class="col-md-8 content" >
                    <h2 class="title">Biography</h2>
                   <p>Mr.Nishank Tyagi is the Founder of Adel Legal. He is a lawyer with years of experience having expertise in civil & commercial litigation, arbitration, banking, insolvency and criminal law. Nishank has represented clients domestic as well as foreign entities from varied sectors before various judicial forums including the Supreme Court of India, various High Courts, National Company Law Tribunal, National Consumer Dispute Resolution Commission, Debt Recovery Tribunal, District Courts amongst others. 
                </p>
                <p>Post his graduation from Amity Law School, Delhi (Guru Gobind Singh Indraprastha University, Delhi), and completion of courses on Cyber Laws from The Indian Law Institute, Delhi and Corporate Laws from Asian School of Cyber Laws, Pune, Nishank worked with some of the best organizations in the legal fraternity such as Chambers of Senior Advocate Ratan.K.Singh, Link Legal India Law Services, BAV Partners and Singh & Associates. 
                </p>
                <p>When he is not practicing, Nishank teaches law. He has travelled to several universities across the country to deliver lectures to law students on various aspects law. 
                </p>
                    <p>Over the years, Nishank has gained experience in handling arbitrations conducted under the rules of International Chamber of Commerce, Singapore International Arbitration Centre, the London Court of International Arbitration and other similar associations and institutions. Nishank has appeared and argued on behalf of various Banks, Government Entities, Statutory Bodies, State Governments, Private Companies and Individuals before various Courts and Tribunals across India.
                    </p>
                </div>
            </div>
    </div>

</div>
