

<div class="footer-basic">
    <footer>
        <div class="social">
            <!-- <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a> -->
            <a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
            <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
        </div>
        <ul class="list-inline">
            <li class="list-inline-item"><a routerLink="">Home</a></li>
            <li class="list-inline-item"><a routerLink="/about">About</a></li>
            <li class="list-inline-item"><a routerLink="/practiceArea">Practice Area</a></li>
            <li class="list-inline-item"><a routerLink="/contactus">Contact</a></li>
        </ul>
        <p class="copyright">Adel Legal © 2021</p>
    </footer>
</div>