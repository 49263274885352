
  <nav class="navbar navbar-expand-lg navbar-light ">
    <a class="logo navbar-brand"><img src="../../assets/logo.jpeg"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <div class="mr-auto"></div>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" aria-current="page" [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/about']" routerLinkActive="active" >About</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/practiceArea']" routerLinkActive="active">Practice Area</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink] ="['/contactus']" routerLinkActive ="active">Contact</a>
        </li>
      </ul>
    </div>
  </nav>
