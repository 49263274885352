import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  items=[
    {'img':'../../assets/images/dispute.png','title':'Dispute Management & Litigation','text':'Our endeavour is to devise a litigation strategy with the commercial interests of our clients. We identify the best legal solution by looking beyond the traditional approaches and thinking creatively.'},

    {'img':'../../assets/images/conversation.png','title':'Arbitration, Mediation & ADR','text':"Our firm offers services to handle arbitration proceedings in entirety, right from the stage of appointment of arbitrators by the Court, obtaining/opposing any interim relief's pending, appointment."},
    {'img':'../../assets/images/agreement.png','title':'Corporate & Commercial','text':'Adel Legal provides consultation on corporate and commercial law matters, including formation and association of Indian, foreign and mixed companies in India, joint ventures, foreign investments.'},
    {'img':'../../assets/images/real-estate.png','title':'Real Estate','text':'Our Real Estate practice involves carrying out Due diligence of property documents, change in ownership documents and conducting title searches for others, homes, real estate projects, townships, industries and acquisitions. '},
    {'img':'../../assets/images/labour.png','title':'Labour & Employment','text':'We have a strong portfolio of clients and transactions in relation to employment, HR and labour laws and have been advising several of its clients, including multinational clients on labour and employment law related matters'},
    {'img':'../../assets/images/family.png','title':'Family Law','text':'Our proficiency extends to providing expert, time bound and effective assistance in various family disputes involving child custody battles, resolving usurping of property by the creation of fake power of attorney and other documentation.'},
    {'img':'../../assets/images/lawyer.png','title':'Pro Bono Services','text':'We provide pro bono legal services to charitable/not for profit organizations who cannot otherwise afford legal counsel. We approach pro bono efforts with the same dedication and energy as work for commercial clients.'},
]
  constructor() { }

  ngOnInit(): void {
  }

}
