import { AboutMeComponent } from './about-me/about-me.component';
import { HomeComponent } from './home/home.component';
import {ContactusComponent} from './contactus/contactus.component';
import { NgModule} from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PracticeAreaComponent } from './practice-area/practice-area.component';

const routes: Routes = [
  
   {path:'about' ,component:AboutMeComponent},
  {path:'practiceArea' ,component:PracticeAreaComponent},
  {path:'contactus',component:ContactusComponent},

  {path:'**' , component:HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
